body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.tabs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  background-color: #000;
  padding: 5px 0;
  /* Reduced padding for shorter tabs */
  font-family: Arial, sans-serif;
  font-size: 13px;
  z-index: 10;
}

.tab-item {
  display: flex;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  transition: border-bottom 0.3s;
  text-decoration: none;
  /* Remove underline for anchor links if any */
  cursor: pointer;
}

.tab-item img {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.tab-item:hover {
  border-bottom: 2px solid #4285f4;
}

.tab-item.active {
  border-bottom: 2px solid #fff;
}

.cse-header {
  padding: 0px 0 0 10px !important;
  background-color: #f1f1f1;
  background-image: -webkit-gradient(
    radial,
    100 36,
    0,
    100 -40,
    120,
    from(#fafafa),
    to(#f1f1f1)
  );
  border-bottom: 1px solid #666;
  border-color: #e5e5e5;
  height: 80px;
  width: 100vm !important;
  display: flex;
  align-items: center;
}

#iframeContainer {
  position: absolute;
  top: calc(5px + 1em);
  /* Adjust based on your tabs div height */
  left: 0;
  width: 100vw;
  height: calc(100vh - 5px - 1em);
  /* Adjust based on your tabs div height */
  z-index: 5;
  /* So it's below tabs */
  overflow: hidden;
}


.nav-link:hover {
    color:black !important;
  }  
.footer-link:hover {
    color:black !important;
  }  
  .active{
    color:black !important;
  }
.card {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 450;
    font-style: normal;
  }

.curved{
  border-bottom-left-radius: 50% 30px; /* Set border radius for bottom-right corner */
  border-bottom-right-radius: 50% 50px; /* Set border radius for bottom-right corner */
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}
/* Target the scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */

}

/* Track (the area behind the scrollbar) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the handle */
  border-top-left-radius:5px;
  border-bottom-left-radius:5px;
  border-bottom-right-radius:5px;
  border-top-right-radius:5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the handle on hover */
}



